import React from "react";
import "./navbar.css";

function Navbar() {
  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          fontSize: "4rem",
          justifyContent: "center",
        }}
      >
        <a>Dheeraj Nandiraju</a>
      </div> */}
    </div>
  );
}

export default Navbar;
