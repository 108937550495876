import React from "react";
import "./home.css";
function Home() {
  return (
    <div>
      {/* <div className="cir"></div> */}
      <div className="parent">
        <div
        className="dheeraj"
        >
          <a>Dheeraj Nandiraju</a>
        </div>
        <div>
          <h1 className="top">PORTFOLIO</h1>
          <h1 className="middle">PORTFOLIO</h1>
          <h1 className="bottom">PORTFOLIO</h1>
        </div>
        <h5 className="te">of a Front-end developer and UI/UX designer</h5>
      </div>
    </div>
  );
}

export default Home;
